
// Vue reactivity
import { computed } from "vue";

// icons
import { add, remove, trash } from "ionicons/icons";

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
          IonGrid, IonButtons, IonButton, IonIcon, IonNote,
          IonSpinner, IonList, IonItem, IonThumbnail, IonLabel, alertController, } from "@ionic/vue";

// API services
import CartService from '@/services/CartService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "CartPage",
  components: {
    IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
    IonGrid, IonButtons, IonButton, IonIcon, IonNote,
    IonSpinner, IonList, IonItem, IonThumbnail, IonLabel,
  },
  setup() {
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingCartItems)
    //const cartItems = computed(() => store.state.cartItems);
    const cartItems = computed(() => store.getters.designCartItems);

    let serverReqFlag: any = null; // setTimeout

    // methods or filters
    const { t } = useI18n();
    const { formatDate } = utils();
    const updateDBCartItemQty = (item: any, timeout = 1000) => {
      if (serverReqFlag) clearTimeout(serverReqFlag);
      serverReqFlag = setTimeout(() => {
        if (userLoggedIn.value == true) {
          CartService.updateUserCartItem(item.designId, item.quantity);
        }
      }, timeout);
    }
    const incrementCartItemQty = (item: any) => {
      item.quantity++;
      updateDBCartItemQty(item);
    }
    const decrementCartItemQty = (item: any) => {
      if (item.quantity > 1) item.quantity--;
      updateDBCartItemQty(item);
    }
    const removeCartItem = async (item: any, itemIdx: number) => {
      const alert = await alertController.create({
        header: t('CartPage.removeCartItem'),
        message: t('CartPage.confirmRemoveCartItem'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('yes'),
            handler: () => {
              cartItems.value.splice(itemIdx, 1);
              item.quantity = 0;
              updateDBCartItemQty(item, 0);
            }
          }
        ]
      });
      return alert.present();
    }
    const onFinishInputCartItemQty = (item: any, e: any) => {
      const inputQty = e.target.value;
      item.quantity = inputQty > 0 ? Math.ceil(+inputQty) : +item.quantity + 1;
      updateDBCartItemQty(item);
    }
    const getCartTotal = () => (cartItems.value.reduce((a: any, b: any) => +a + (b.unitPrice * b.quantity), 0));

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add, remove, trash,
      
      // methods
      formatDate, incrementCartItemQty, decrementCartItemQty, removeCartItem, onFinishInputCartItemQty, getCartTotal,

      // variables
      loading, cartItems,
    };
  },
};
